<script setup>
import { ref, computed, onBeforeMount, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import { getFormattedDate } from "@/utils";
import axios from "axios";
import { convertTimestampToLocal } from "@/utils";
import * as XLSX from "xlsx";

// import { getFormattedDate } from "@/utils";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  clickLoading: {
    type: Boolean,
    required: true,
  },
});

const body = document.getElementsByTagName("body")[0];

// const router = useRouter();
// const jobId = computed(() => router.currentRoute.value.params.id);

const store = useStore();

const job = computed(() => store.getters["jobData/getSelectedJob"]);

const sortedData = computed(() => {
  if (!sortColumn.value) return props.data;

  return [...props.data].sort((a, b) => {
    let aVal = a[sortColumn.value];

    let bVal = b[sortColumn.value];

    if (sortColumn.value === "uploadDate") {
      aVal = new Date(a.createdAt);
      bVal = new Date(b.createdAt);
    }

    if (aVal < bVal) return sortDirection.value === "asc" ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === "asc" ? 1 : -1;
    return 0;
  });
});

const trackingSearch = ref("");

const sortColumn = ref("");
const sortDirection = ref("asc");
const currentPage = ref(1);
const itemsPerPage = ref(10);
const itemsPerPageOptions = [5, 10, 25, 50];

const sort = (column) => {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortColumn.value = column;
    sortDirection.value = "asc";
  }
};

const totalPages = computed(() =>
  Math.ceil(sortedData.value.length / itemsPerPage.value)
);

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;

  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers

  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }

  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }

  // Add ellipsis if necessary
  if (startPage > 1) range.unshift("...");
  if (endPage < totalPageCount) range.push("...");

  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);

  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("donationData/fetchDonations");
  // await store.dispatch("trackingData/fetchTrackings", { jobId: jobId.value });
});

const loading = ref(false);

// const onPrint = async () => {
//   loading.value = true;
//   const histories = await axios.get(
//     `${process.env.VUE_APP_API_URL}/messages/history/${job.value.jobId}`
//   );
//   if (!histories.data) return;

//   downloadCSV(
//     histories.data,
//     `${job.value.name} ${convertTimestampToLocal(job.value.startDate)}`
//   );
//   loading.value = false;
// };

// const downloadCSV = (downloadData, fileName) => {
//   if (!downloadData || !downloadData[0]) return;

//   // Extract the headers
//   const headers = Object.keys(downloadData[0]);

//   // Create an array of CSV rows
//   const csvRows = [];

//   // Add the header row
//   csvRows.push(headers.join(","));

//   // Add the data rows
//   for (const row of downloadData) {
//     const values = headers.map((header) => {
//       const val =
//         row[header] === null || row[header] === undefined ? "" : row[header];
//       return `"${val}"`; // Enclose each value in quotes to handle commas within fields
//     });
//     csvRows.push(values.join(","));
//   }

//   // Combine all rows into a single CSV string
//   const csvString = csvRows.join("\n");

//   // Create a Blob from the CSV string
//   const blob = new Blob([csvString], { type: "text/csv" });

//   // Create a link element
//   const link = document.createElement("a");

//   // Set the URL using the Blob
//   link.href = URL.createObjectURL(blob);

//   // Set the file name
//   link.download = `${fileName}.csv`;

//   // Append the link to the body (required for Firefox)
//   document.body.appendChild(link);

//   // Programmatically click the link to trigger download
//   link.click();

//   // Remove the link from the body
//   document.body.removeChild(link);
// };

const onPrint = async () => {
  loading.value = true;
  const histories = await axios.get(
    `${process.env.VUE_APP_API_URL}/messages/history/${job.value.jobId}`
  );
  if (!histories.data) return;

  const donationLists = computed(
    () => store.getters["donationData/getDonations"]
  );

  downloadCSV(
    histories.data,
    [...props.data].map((item) => ({ ...item })),
    [...donationLists.value].map((item) => ({ ...item })),
    `${job.value.name} ${convertTimestampToLocal(job.value.startDate)}`
  );
  loading.value = false;
};

const downloadCSV = (downloadData, clicksData, donationData, fileName) => {
  if (!downloadData || !downloadData[0]) return;
  if (!clicksData || !clicksData[0]) return;
  if (!donationData || !donationData[0]) return;

  // Create workbook and add sheets
  const workbook = XLSX.utils.book_new();

  // Define the clicks field mapping with desired order
  const fieldMapping = {
    phonenumber: "Phone Number",
    guidlink: "Link",
    clicks: "Clicks",
    createdAt: "Date",
  };

  const fieldNonOpt = {
    from: "From Number",
    message: "Replies",
  };

  const fieldDonation = {
    recipientname: "Recipient Organization Name",
    amount: "Amount",
    firstName: "First Name",
    lastName: "Last Name",
    mobile: "Mobile",
    status: "Status",
  };

  // Extract the headers
  // const headers = Object.keys(downloadData[0]);
  // Use the ordered fields instead of all headers
  const clicksHeaders = Object.values(fieldMapping); // Get new header names in order
  const selectedFields = Object.keys(fieldMapping);

  //Non-Opt Out replies
  const nonOptHeader = Object.values(fieldNonOpt);
  const selectedNonOptFields = Object.keys(fieldNonOpt);

  //const Opt Out replies
  const optHeader = Object.values(fieldNonOpt);
  const selectedOptFields = Object.keys(fieldNonOpt);

  //Donation Header
  const donationHeaders = Object.values(fieldDonation); // Get new header names in order
  const selectedDonationFields = Object.keys(fieldDonation);

  // Create data array with headers as first row
  // const sheetData = [headers];
  const clicksSheetData = [clicksHeaders];
  const NonOptSheetData = [nonOptHeader];
  const optSheetData = [optHeader];
  const donationSheetData = [donationHeaders];

  // Add the data rows
  // for (const row of downloadData) {
  //   const values = headers.map((header) => {
  //     const val =
  //       row[header] === null || row[header] === undefined ? "" : row[header];
  //     return val;
  //   });
  //   sheetData.push(values);
  // }

  //Add the clicks data rows
  for (const row of clicksData) {
    const values = selectedFields.map((originalField) => {
      // Special handling for createdAt field
      if (originalField === "createdAt") {
        return getFormattedDate(row[originalField]) || "";
      }

      // Special handling for clicks field - convert to string to maintain consistent alignment
      if (originalField === "clicks") {
        const clickValue =
          row[originalField] === null || row[originalField] === undefined
            ? "0"
            : row[originalField];
        return String(clickValue); // Ensure it's always a string
      }

      const val =
        row[originalField] === null || row[originalField] === undefined
          ? ""
          : row[originalField];
      return val;
    });
    clicksSheetData.push(values);
  }

  // Add the Non-Opt out data rows - filtered for non-STOP messages
  for (const row of downloadData) {
    // Check if status is RECEIVED and message is not a variation of 'stop'
    if (
      row.status === "RECEIVED" &&
      row.message &&
      !row.message
        .trim()
        .toLowerCase()
        .match(/^stop[\s!.=]*$/) // matches only standalone 'stop' with optional punctuation
    ) {
      const values = selectedNonOptFields.map((originalField) => {
        const val =
          row[originalField] === null || row[originalField] === undefined
            ? ""
            : row[originalField];
        return val;
      });
      NonOptSheetData.push(values);
    }
  }

  // Add the Opt out data rows - filtered for non-STOP messages
  for (const row of downloadData) {
    if (
      row.status === "RECEIVED" &&
      row.message &&
      row.message.trim().toLowerCase() === "stop" // matches only standalone 'stop' with optional punctuation
    ) {
      const values = selectedOptFields.map((originalField) => {
        const val =
          row[originalField] === null || row[originalField] === undefined
            ? ""
            : row[originalField];
        return val;
      });
      optSheetData.push(values);
    }
  }

  //Add the donation data rows
  for (const row of donationData) {
    const values = selectedDonationFields.map((originalField) => {
      const val =
        row[originalField] === null || row[originalField] === undefined
          ? ""
          : row[originalField];
      return val;
    });
    donationSheetData.push(values);
  }

  // Create worksheet and add to workbook
  // const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  const clicskSheet = XLSX.utils.aoa_to_sheet(clicksSheetData);
  const NonOptSheet = XLSX.utils.aoa_to_sheet(NonOptSheetData);
  const optSheet = XLSX.utils.aoa_to_sheet(optSheetData);
  const donationSheet = XLSX.utils.aoa_to_sheet(donationSheetData);

  // Add first sheet with original data
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Original Data");

  // Add second sheet with same data
  XLSX.utils.book_append_sheet(workbook, clicskSheet, "Clicks");

  XLSX.utils.book_append_sheet(workbook, donationSheet, "Donations");

  XLSX.utils.book_append_sheet(workbook, NonOptSheet, "Non-Opt out Replies");

  XLSX.utils.book_append_sheet(workbook, optSheet, "Opt Outs");

  // Generate the file
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex print-icon mb-4">
              <search-bar v-model="trackingSearch" />

              <div class="print-icon">
                <argon-button
                  color="light"
                  :disabled="loading"
                  @click="onPrint"
                >
                  <i class="fas fa-file-export text-xl mouse-pointer"></i>
                  <!-- <i class="fa fa-print text-xl text-warning"></i> -->
                </argon-button>
              </div>
            </div>
            <div v-if="clickLoading" class="text-center my-4">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="mt-2">Loading data...</p>
            </div>
            <div v-else class="table-responsive p-0">
              <table
                class="table align-items-center justify-content-center mb-0"
              >
                <thead>
                  <tr>
                    <th
                      @click="sort('listName')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Phone Number
                    </th>
                    <th
                      @click="sort('identityId')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      GUID
                    </th>

                    <th
                      @click="sort('clicks')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Clicks
                      <span v-if="sortColumn === 'clicks'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      @click="sort('uploadDate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in paginatedData"
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
                  >
                    <td class="w-15 px-4 text-center">
                      {{ item.phonenumber }}
                    </td>
                    <td class="w-15 px-4 text-center">
                      win26.co/{{ item.guidlink }}
                    </td>
                    <td class="w-5 px-4 text-center">
                      {{ item.clicks ? item.clicks : 0 }}
                    </td>
                    <td class="w-10 px-4 text-center">
                      {{ getFormattedDate(item.createdAt) || 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                v-if="totalPages > 1"
                class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5"
              >
                <div>
                  <span class="me-2">Rows per page:</span>
                  <select v-model="itemsPerPage" @change="resetPage">
                    <option
                      v-for="option in itemsPerPageOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="pagination-nav">
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="goToFirstPage"
                    :disabled="currentPage === 1"
                    title="First Page"
                  >
                    &lt;&lt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    title="Previous Page"
                  >
                    &lt;
                  </button>
                  <template
                    v-for="pageNum in visiblePageNumbers"
                    :key="pageNum"
                  >
                    <button
                      v-if="pageNum !== '...'"
                      class="btn btn-sm me-1"
                      :class="
                        pageNum === currentPage
                          ? 'btn-primary'
                          : 'btn-secondary'
                      "
                      @click="goToPage(pageNum)"
                    >
                      {{ pageNum }}
                    </button>
                    <span v-else class="mx-1">...</span>
                  </template>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    title="Next Page"
                  >
                    &gt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToLastPage"
                    :disabled="currentPage === totalPages"
                    title="Last Page"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.margin-left {
  margin-left: 12px;
}

.print-icon {
  justify-content: space-between;
}

.print-icon {
  display: flex;
  align-items: center;
}

.mouse-pointer {
  cursor: pointer;
}
</style>
