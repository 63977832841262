<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import PreviewMediaModal from "./PreviewMediaModal.vue";
import Tooltip from "@/components/Tooltip/index.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const identities = computed(() => store.getters["identityData/getIdentities"]);
const mediaData = computed(() => store.getters["mediaData/getMedia"]);
const loading = computed(() => store.getters["mediaData/isLoading"]);
const error = computed(() => store.getters["mediadata/getError"]);
const userId = computed(() => store.getters["user/currentUser"]?.id);
const identity = computed(() => store.getters["mediaData/getIdentity"]);
const isLoading = ref(false);

const files = ref([]);
const showModal = ref(false);
const selectedMedia = ref({});
const mediaSearch = ref("");
const sortColumn = ref("");
const sortDirection = ref("asc");

const tableHeadings = ref([
  { field: "Preview", value: "preview" },
  { field: "", value: "titleIdentity" },
  { field: "Created Date", value: "createdDate" },
  { field: "", value: "actions" },
]);

const filteredMedia = computed(() => {
  return mediaData.value.filter((media) => {
    const matchesSearch =
      !mediaSearch.value ||
      media.title.toLowerCase().includes(mediaSearch.value.toLowerCase());
    return matchesSearch;
  });
});

const sortedData = computed(() => {
  if (!sortColumn.value) return filteredMedia.value;

  return [...filteredMedia.value].sort((a, b) => {
    let aVal, bVal;

    if (sortColumn.value === "titleIdentity") {
      aVal = (a.title || a.identity || "").toLowerCase();
      bVal = (b.title || b.identity || "").toLowerCase();
    } else if (sortColumn.value === "createdDate") {
      aVal = new Date(a.createdDate);
      bVal = new Date(b.createdDate);
    } else {
      aVal = a[sortColumn.value];
      bVal = b[sortColumn.value];
    }

    if (aVal < bVal) return sortDirection.value === "asc" ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === "asc" ? 1 : -1;
    return 0;
  });
});

const paginatedMedia = computed(() => {
  return sortedData.value;
});

onMounted(async () => {
  if (userId.value) {
    await store.dispatch("mediaData/fetchMedia", { userId: userId.value });
  }
});

const sort = (column) => {
  if (column === "titleIdentity" || column === "createdDate") {
    if (sortColumn.value === column) {
      sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
    } else {
      sortColumn.value = column;
      sortDirection.value = "asc";
    }
  }
};

const toggleAssignMediaPanel = () =>
  store.commit("mediaData/toggleAssignMediaPanel");

const triggerFileUpload = () => {
  document.querySelector('input[type="file"]').click();
};

const getIdentityName = (identityId) => {
  const found = identities.value?.find((item) => item.identityId == identityId);
  return found?.identityName;
};

const handleFileUpload = async ($event) => {
  const target = $event?.target;
  if (target && target.files) {
    const fileList = Array.from(target.files);
    const filesArray = fileList.map((file) => ({
      file: file,
      title: file.name,
    }));
    files.value = filesArray;
    if (files.value.length > 0) {
      await uploadFiles();
    }
    // Clear the file input
    $event.target.value = null;
  }
};

const uploadFiles = async () => {
  isLoading.value = true;
  for (const fileItem of files.value) {
    try {
      let mediaType = null;
      if (fileItem.file.type?.includes("image")) {
        mediaType = "IMAGE";
      }
      if (fileItem.file.type?.includes("video")) {
        mediaType = "VIDEO";
      }
      const formData = new FormData();
      formData.append("account_id", userId.value);
      formData.append("title", fileItem.title);
      formData.append("identity_id", identity.value.identityId);
      formData.append("file", fileItem.file);
      formData.append("media_type", mediaType);

      // Make API call to upload the file
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/media/upload`,
        // `${process.env.VUE_APP_API_URL}/media/upload-to-bandwidth`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("File uploaded successfully:", fileItem.title);
        store.dispatch("mediaData/fetchMedia", { userId: userId.value });
        isLoading.value = false;
        files.value = [];
      } else {
        console.error("Failed to upload file:", fileItem.title);
      }
    } catch (error) {
      console.error("Error uploading file:", fileItem.title, error);
    }
  }
};

const onMediaClick = (media) => {
  selectedMedia.value = media;
  if (showModal.value === false) showModal.value = true;
};

const onAssign = (media) => {
  store.commit("mediaData/setAssignedMedia", media);
  toggleAssignMediaPanel();
};

const onRemove = async (media) => {
  await store.dispatch("mediaData/deleteMedia", media.mediaId);
  await store.dispatch("mediaData/fetchMedia", { userId: userId.value });
};
</script>

<template>
  <div class="assign-media fixed-plugin">
    <div class="shadow-lg overflow-scroll card p-4">
      <div v-if="isLoading" class="card text-center my-4 spinner-center">
        <div class="spinner-border text-primary loading-spinner" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">Loading data...</p>
      </div>
      <div class="pb-0">
        <div class="float-start">
          <h5 class="">Media Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAssignMediaPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center mb-4">
          <search-bar v-model="mediaSearch" />
          <input type="file" style="display: none" @change="handleFileUpload" />
          <argon-button class="ms-auto" @click.prevent="triggerFileUpload()">
            Upload
          </argon-button>
        </div>
        <div v-if="loading">Loading...</div>
        <div v-else-if="error">{{ error }}</div>
        <div v-else class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  v-for="(heading, index) in tableHeadings"
                  :key="index"
                  @click="sort(heading.value)"
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                >
                  <span v-if="heading.value === 'titleIdentity'">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <span class="me-2">Title</span>/<span class="ms-2"
                        >Identity</span
                      >
                    </div>
                  </span>
                  <span v-else>
                    {{ heading.field }}
                  </span>
                  <span v-if="sortColumn === heading.value">
                    {{ sortDirection === "asc" ? "▲" : "▼" }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(media, index) in paginatedMedia" :key="index">
                <td class="px-2 preview-content">
                  <img
                    :src="media.thumbnailUrl"
                    class="media-image me-3"
                    alt="preview"
                    @click.prevent="onMediaClick(media)"
                  />
                </td>
                <td class="px-2 title-content">
                  <div
                    class="d-flex flex-column justify-content-center align-items-start"
                  >
                    <Tooltip :title="media.title">
                      <strong>{{ media.title }}</strong>
                      <span>{{ getIdentityName(media.identityId) }}</span>
                    </Tooltip>
                  </div>
                </td>
                <td class="px-2 date-content">
                  <div
                    class="d-flex flex-column justify-content-center align-items-start"
                  >
                    <span>{{ media.createdDate }}</span>
                    <span>Size: {{ media.sizeBytes / 1000 }} KB</span>
                  </div>
                </td>
                <td class="assign-content">
                  <div class="d-flex justify-content-end align-items-center">
                    <argon-button
                      color="primary"
                      size="sm"
                      class="me-2 border-radius-2xl px-2"
                      @click.prevent="onAssign(media)"
                    >
                      Assign
                    </argon-button>
                    <argon-button
                      color="warning"
                      size="sm"
                      class="action-btn rounded-circle ms-auto px-2"
                      @click.prevent="onRemove(media)"
                    >
                      <i
                        class="fas fa-trash-alt text-xs text-white"
                        aria-hidden="true"
                      ></i>
                    </argon-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <preview-media-modal
            :show="showModal"
            :mediaType="selectedMedia.mediaType"
            :mediaSrc="selectedMedia.previewUrl"
            @close="showModal = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.assign-media.fixed-plugin .card {
  width: 780px;
  right: -780px;
  z-index: 9999;
}

.assign-media.fixed-plugin.show .card {
  right: 0;
}

.spinner-center {
  align-items: center;
}

.table-responsive {
  overflow-x: hidden;
}

.preview-content {
  width: 100px;
  min-width: 100px;
}

.title-content {
  width: 230px;
  max-width: 300px;
}

.title-content strong,
.title-content span {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.date-content {
  width: 150px;
  min-width: 150px;
}

.assign-content {
  width: 100px;
  min-width: 100px;
}

.media-image {
  max-width: 100px;
  height: auto;
  object-fit: cover;
}
</style>
